export default{
    silmekont:false,
    solkontrol:false,

    domainId:'bos',
    domainName:'',

/// Foto yükleme degiskenleri

    uploadtaskk:null,
    progres1:null,

/// Veri çekme Değişkenleri

    BelediyeGenelBilgiler:{
        belediyeadi:'',
        belediyeadresi:'',
        faxnumarasi:'',
        ilceadi:'',
        iletisim:'',
        kapak:'',
        mail:'',
    },
    BaskanBilgileri:{
        baskanadi:'',
        baskanslogan:'',
        baskanface:'',
        baskantwit:'',
        baskaninsta:'',
        baskanprofil:'' ,
        ozgecmis:'',
        mesaj:'',
        Dosyalar:[],              
       }, 
    IlceBilgiler:{
        tarihce:'',
        Dosyalar:[]
    },
    Birimler:{},
    Uyeler:{},
    Muhtarlar:{},
    Kararlar:{},
    Raporlar:{},
    Projeler:{},
    Haberler:{},
    Sehitler:{},
    Slides:{},
    Yerler:{},
    Vefatlar:{},
    Evlenenler:{},
    Belgeler:{},
    DuyuruIlanlar:{},
    duyuruilankontrol:false,
    belgekontrol:false,
    evlenenkontrol:false,
    vefatkontrol:false,
    yerkontrol:false,
    sehitkontrol:false,
    ilcekontrol:false,
    haberkontrol:false,
    projekontrol:false,
    genelkontrol:false,
    baskankontrol:false,
    raporlarkontrol:false,
    birimlerkontrol:false,
    muhtarlarkontrol:false,
    kararlarkontrol:false,
    uyelerkontrol:false,
    slideskontrol:false,
}
