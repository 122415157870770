<template>
  <div class="col-lg-3 text-center">
    <div class="sidebar shadow sidebar-sticky">
      <div class="sidebar-widget cat-widget">
        <div class="widget-content">
          <div class="sidebar-title">
            <h4 class="color-e">Hızlı Menü</h4>
          </div>
          <ul class="cat-links clearfix">
            <li></li>
            <li>
              <a
                href="/BaskanOzgecmis"
                aria-current="page"
                class="router-link-exact-active router-link-active"
                >Başkanımız</a
              >
            </li>
            <li><a href="/Haberler" class="">Haberler</a></li>
            <li><a href="/Duyurular" class="">Duyurular</a></li>
            <li><a href="/Galeri" class="">Galeri</a></li>
            <li><a href="/VefatEdenler" class="">Vefat Edenler</a></li>
            <li><a href="/BelgeOrnekleri" class="">Belge Örnekleri</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Sidebar",
};
</script>