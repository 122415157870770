import Vue from 'vue'
import App from './App.vue'
import * as VueFire from 'vuefire'
import './registerServiceWorker'
import router from './router'
import store from './store'
require('firebase/app');
Vue.use(VueFire)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
