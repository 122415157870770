<template>
  <div>
    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
    >
      <i class="bi bi-arrow-up-short"></i>
    </a>
  </div>
</template>
<script>
export default {
  name: "BackToTop",
};
</script>