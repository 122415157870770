<template>
  <div>
    <nav class="navbar navbar-default navbar-trans navbar-expand-lg fixed-top">
      <div class="container">
        <button
          class="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarDefault"
          aria-controls="navbarDefault"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <a class="navbar-brand text-brand" href="/">
          {{ Belediyebilgiler.belediyeadi }}
          <span class="color-b d-none d-xl-inline-block">Belediyesi</span></a
        >

        <div
          class="navbar-collapse collapse justify-content-center"
          id="navbarDefault"
        >
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link active" href="/">Anasayfa</a>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                >Başkanımız</a
              >
              <div class="dropdown-menu">
                <a class="dropdown-item" href="/BaskanOzgecmis"
                  >Başkan Özgeçmiş</a
                >
                <a class="dropdown-item" href="/BaskaninMesaji"
                  >Başkanın Mesajı</a
                >
                <a class="dropdown-item" href="/BaskanGaleri">Başkan Galeri</a>
                <!-- <a class="dropdown-item" href="/BaskanaSor">Başkan'a Sor</a> -->
              </div>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                >Kurumsal</a
              >
              <div class="dropdown-menu">
                <a class="dropdown-item" href="/Birimler">Birimler</a>
                <a class="dropdown-item" href="/MeclisUyeleri"
                  >Meclis Üyeleri</a
                >
                <a class="dropdown-item" href="/Muhtarlar">Muhtarlar</a>
                <a class="dropdown-item" href="/MeclisKararlari"
                  >Meclis Kararları</a
                >
                <a class="dropdown-item" href="/Ihaleler">İhaleler</a>
                <a class="dropdown-item" href="/Galeri">Galeri</a>
              </div>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                >{{ Belediyebilgiler.belediyeadi }}</a
              >
              <div class="dropdown-menu">
                <a class="dropdown-item" href="/Tarihce">Tarihçe</a>
                <a class="dropdown-item" href="/Sehitlerimiz">Şehitlerimiz</a>
                <a class="dropdown-item" href="/Haberler">Haberler</a>
                <a class="dropdown-item" href="/Projeler">Projeler</a>
                <a class="dropdown-item" href="/Duyurular">Duyurular</a>

                <a class="dropdown-item" href="/KentRehberi">Kent Rehberi</a>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                >Hizmetlerimiz</a
              >
              <div class="dropdown-menu">
                <a class="dropdown-item" href="https://www.belediye.gov.tr"
                  >e-Belediye</a
                >
                <a class="dropdown-item" href="https://www.eczaneler.gen.tr"
                  >Nöbetçi Eczaneler</a
                >
                <a class="dropdown-item" href="/VefatEdenler">Vefat Edenler</a>
                <a class="dropdown-item" href="/NikahDuyurulari"
                  >Nikah Duyuruları</a
                >
                <a class="dropdown-item" href="/BelgeOrnekleri"
                  >Belge Örnekleri</a
                >
              </div>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="/Iletisim">İletişim</a>
            </li>
          </ul>
        </div>

        <!-- <button
          type="button"
          class="btn btn-b-n navbar-toggle-box navbar-toggle-box-collapse"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo01"
        >
          <i class="bi bi-search"></i>
        </button> -->
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      Belediyebilgiler: {},
      domAd: "",
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    this.domAd = domainAd;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
  },
};
</script>

<style scoped>
</style>
