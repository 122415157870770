<template>
  <div>
    <section class="section-footer">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <div class="widget-a">
              <div class="w-header-a">
                <h3 class="w-title-a text-brand-white">
                  {{ Belediyebilgiler.belediyeadi }} BELEDIYESI
                </h3>
                <ul class="list-unstyled">
                  <li class="color-e">
                    <img
                      style="max-width: 300px"
                      class="mt-2 mb-2"
                      :src="Belediyebilgiler.kapak"
                      alt=""
                    />
                  </li>
                </ul>
              </div>
              <div class="w-body-a">
                <p class="w-text-a color-text-a">
                  {{ Belediyebilgiler.belediyeadresi }}
                </p>
              </div>
              <div class="w-footer-a">
                <ul class="list-unstyled">
                  <li class="color-e">
                    <span class="color-text-a">Telefon .</span>
                    {{ Belediyebilgiler.iletisim }}
                  </li>
                  <li class="color-e">
                    <span class="color-text-a">E-Posta .</span>
                    {{ Belediyebilgiler.mail }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 section-md-t3">
            <div class="widget-a">
              <div class="w-header-a">
                <h3 class="w-title-a text-brand-white">Hizmetlerimiz</h3>
              </div>
              <div class="w-body-a">
                <div class="w-body-a">
                  <ul class="list-unstyled">
                    <li class="item-list-a">
                      <i class="bi bi-chevron-right"></i>
                      <a href="https://www.belediye.gov.tr">E-Belediye</a>
                    </li>
                    <li class="item-list-a">
                      <i class="bi bi-chevron-right"></i>
                      <a href="https://www.eczaneler.gen.tr"
                        >Nöbetçi Eczaneler</a
                      >
                    </li>
                    <li class="item-list-a">
                      <i class="bi bi-chevron-right"></i>
                      <a href="/VefatEdenler">Vefat Edenler</a>
                    </li>
                    <li class="item-list-a">
                      <i class="bi bi-chevron-right"></i>
                      <a href="/BelgeOrnekleri">Belge Örnekleri</a>
                    </li>
                    <li class="item-list-a">
                      <i class="bi bi-chevron-right"></i>
                      <a href="/NikahDuyurulari">Nikah Duyuruları</a>
                    </li>
                    <li class="item-list-a">
                      <i class="bi bi-chevron-right"></i>
                      <a href="/KentRehberi">Kent Rehberi</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 section-md-t3">
            <div class="widget-a">
              <div class="w-header-a">
                <h3 class="w-title-a text-brand-white">
                  {{ Belediyebilgiler.belediyeadi }}
                </h3>
              </div>
              <div class="w-body-a">
                <ul class="list-unstyled">
                  <li class="item-list-a">
                    <i class="bi bi-chevron-right"></i>
                    <a href="/Tarihce">Tarihçe</a>
                  </li>
                  <li class="item-list-a">
                    <i class="bi bi-chevron-right"></i>
                    <a href="/Galeri">Galeri</a>
                  </li>
                  <li class="item-list-a">
                    <i class="bi bi-chevron-right"></i>
                    <a href="/Sehitlerimiz">Şehitlerimiz</a>
                  </li>
                  <li class="item-list-a">
                    <i class="bi bi-chevron-right"></i>
                    <a href="https://www.belediye.gov.tr">E-Belediye</a>
                  </li>
                  <li class="item-list-a">
                    <i class="bi bi-chevron-right"></i>
                    <a href="/BaskanOzgecmis">Başkanımız</a>
                  </li>
                  <li class="item-list-a">
                    <i class="bi bi-chevron-right"></i>
                    <a href="/BaskaninMesaji">Başkanın Mesajı</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <nav class="nav-footer">
              <ul class="list-inline">
                <li class="list-inline-item">
                  <a href="/">Anasayfa</a>
                </li>
                <li class="list-inline-item">
                  <a href="/BaskanOzgecmis">Başkan</a>
                </li>
                <li class="list-inline-item">
                  <a href="/MeclisKararlari">Kurumsal</a>
                </li>
                <li class="list-inline-item">
                  <a href="/Haberler">{{ Belediyebilgiler.belediyeadi }}</a>
                </li>
                <li class="list-inline-item">
                  <a href="/Iletisim">İletişim</a>
                </li>
              </ul>
            </nav>
            <div class="socials-a">
              <ul class="list-inline">
                <li class="list-inline-item">
                  <a :href="Belediyebilgiler.face">
                    <i class="bi bi-facebook" aria-hidden="true"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a :href="Belediyebilgiler.twit">
                    <i class="bi bi-twitter" aria-hidden="true"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a :href="Belediyebilgiler.insta">
                    <i class="bi bi-instagram" aria-hidden="true"></i>
                  </a>
                </li>
                <!-- <li class="list-inline-item">
                  <a href="#">
                    <i class="bi bi-linkedin" aria-hidden="true"></i>
                  </a>
                </li> -->
              </ul>
            </div>
            <div class="copyright-footer">
              <p class="copyright color-text-a">
                Copyright &copy;
                <span class="color-e"
                  >{{ Belediyebilgiler.belediyeadi }} Belediyesi</span
                >
                Tüm hakları saklıdır.
              </p>
            </div>
            <div class="credits">
              <a href="https://zeplinx.com/">ZeplinX</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      Belediyebilgiler: {},
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
  },
};
</script>

<style scoped>
</style>
