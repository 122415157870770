<template>
  <div>
    <div class="click-closed"></div>
    <!--/ YAN MENU /-->
    <div class="box-collapse">
      <div class="title-box-d">
        <h3 class="title-d">Ara</h3>
      </div>
      <span class="close-box-collapse right-boxed bi bi-x"></span>
      <div class="box-collapse-wrap form">
        <form class="form-a">
          <div class="row">
            <div class="col-md-12 mb-2">
              <div class="form-group">
                <label class="pb-2" for="Type">Anahtar Kelime</label>
                <input
                  type="text"
                  class="form-control form-control-lg form-control-a"
                  placeholder="Anahtar Kelime"
                />
              </div>
            </div>
            <div class="col-md-6 mb-2">
              <div class="form-group mt-3">
                <label class="pb-2" for="Type">Tür</label>
                <select
                  class="form-control form-select form-control-a"
                  id="Type"
                >
                  <option>Hepsi</option>
                  <option>Meclis Kararı</option>
                  <option>Evrak</option>
                  <option>Haber</option>
                  <option>Arşiv</option>
                </select>
              </div>
            </div>
            <div class="col-md-6 mb-2">
              <div class="form-group mt-3">
                <label class="pb-2" for="date">Tarih</label>
                <select
                  class="form-control form-select form-control-a"
                  id="date"
                >
                  <option>Tüm Zamanlar</option>
                  <option>2021</option>
                  <option>2020</option>
                  <option>2019</option>
                  <option>2018</option>
                </select>
              </div>
            </div>

            <div class="col-md-12">
              <button type="submit" class="btn btn-b">Ara</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SearchBar",
};
</script>