<template>
  <div>
    <!-- ======= MAIN ======= -->
    <main id="main">
      <!-- ======= BAŞLIK ======= -->
      <section class="intro-single">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="title-single-box">
                <h1 class="title-single">Belediye Başkanımız</h1>
              </div>
            </div>
            <div class="col-md-12 col-lg-4">
              <nav
                aria-label="breadcrumb"
                class="breadcrumb-box d-flex justify-content-lg-end"
              >
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/">Anasayfa</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Başkanın Mesajı
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <!-- ======= BAŞLIK ======= -->

      <!-- ======= HAKKINDA ======= -->
      <section class="section-about">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 position-relative">
              <div class="about-img-box">
                <img
                  style="width: 35%; margin-left: 25%"
                  :src="BaskanBilgisi.kapak"
                  :alt="BaskanBilgisi.baskanadi"
                  class="img-fluid"
                />
              </div>
              <div class="sinse-box">
                <h3 class="sinse-title">
                  {{ BaskanBilgisi.baskanadi }}
                  <span></span>
                  <br />
                </h3>
                <p>{{ BaskanBilgisi.baskanslogan }}</p>
              </div>
            </div>
            <div class="col-md-12 section-t8 position-relative">
              <div class="row">
                <div class="col-lg-9 section-md-t3">
                  <div class="title-box-d">
                    <h3 class="title-d">
                      Her Zaman
                      <span class="color-b">
                        {{ Belediyebilgiler.belediyeadi }}
                      </span>
                      <br />
                      .
                    </h3>
                  </div>
                  <p class="color-text-b">
                    {{ BaskanBilgisi.mesaj }}
                  </p>
                </div>
                <Sidebar />
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ======= HAKKINDA ======= -->
    </main>
    <!-- ======= MAIN ======= -->
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
export default {
  name: "BaskanMesaj",
  data() {
    return {
      BaskanBilgisi: {},
      Belediyebilgiler: {},
    };
  },
  components: {
    Sidebar,
  },
  async created() {
    // let scrp1 = document.createElement("script");
    // scrp1.setAttribute("src", "/js/main.js");
    // document.head.appendChild(scrp1);
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Baskan");
    this.BaskanBilgisi = this.$store.getters.getBaskan;
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
  },
};
</script>

<style scoped>
</style>
