<template>
  <div>
    <!-- ======= UST SLIDER ======= -->
    <div class="intro intro-carousel swiper-container position-relative">
      <div class="swiper-wrapper">
        <div
          v-for="(haber, index) in Haberler.slice(0, 5)"
          :key="index"
          class="swiper-slide carousel-item-a intro-item bg-image"
          :style="{
            backgroundImage: `url('${haber.kapak}')`,
          }"
          style="background-image: url(img/slider/slide-1.jpg)"
        >
          <div class="overlay overlay-a"></div>
          <div class="intro-content display-table">
            <div class="table-cell">
              <div class="container">
                <div class="row">
                  <div class="col-lg-10">
                    <div class="intro-body">
                      <h1 class="intro-title mb-4">
                        <span class="color-b"
                          >{{ haber.haberAdi.slice(0, 35) }}...</span
                        >
                      </h1>

                      <p class="intro-title-top">
                        {{ haber.haberAciklamasi.slice(0, 300) }}...<br /><br />
                      </p>

                      <p class="intro-subtitle intro-price">
                        <a @click="detayagit(haber.haberId)"
                          ><span class="price-a">Devamını Oku</span></a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- SLİDER İTEM SON -->
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <!-- ======= UST SLIDER ======= -->

    <!-- ======= MAIN ======= -->
    <main id="main">
      <!-- ======= ÖNEMLİ DUYURU ======= -->
      <a class="text-decoration-none" href="https://covid19.saglik.gov.tr/">
        <div class="text-center ustNav">
          <h5 class="text-white py-2 mb-0 font-weight-bold">
            Covid-19 Hakkında Bilgilendirme
          </h5>
        </div>
      </a>
      <!-- ======= ÖNEMLİ DUYURU ======= -->

      <!-- ======= DUYURULAR ======= -->
      <section class="section-duyuru section-t8 nav-arrow-a">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="title-wrap d-flex justify-content-between">
                <div class="title-box">
                  <h2 class="title-a">Duyurular</h2>
                </div>
                <div class="title-link">
                  <a href="/Duyurular"
                    >Tümünü Gör
                    <span class="bi bi-chevron-right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div id="testimonial-carousel" class="swiper-container">
            <div class="swiper-wrapper">
              <!-- DUYURU İTEM BAŞLANGIÇ -->
              <div
                v-for="(ilan, index) in Ilanlar.slice(0, 5)"
                :key="index"
                class="carousel-item-a swiper-slide"
              >
                <div class="testimonials-box">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="testimonial-img">
                        <img
                          style="object-fit: cover; height: 360px"
                          :src="ilan.kapak"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="testimonial-author-box">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="currentColor"
                          class="bi bi-megaphone-fill"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z"
                          />
                        </svg>
                        <h5 class="testimonial-author">
                          <a @click="duyurudetayagit(ilan.duyruilanId)">{{
                            ilan.duyruilanAdi
                          }}</a>
                        </h5>
                      </div>

                      <div class="testimonials-content">
                        <p class="testimonial-text">
                          {{ ilan.duyruilanAciklamasi.slice(0, 300) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- DUYURU İTEM SON -->
            </div>
          </div>
          <div
            class="testimonial-carousel-pagination carousel-pagination"
          ></div>
        </div>
      </section>
      <!-- ======= DUYURULAR ======= -->

      <!-- ======= HİZMETLERİMİZ SLIDER VERSION ======= -->
      <section class="section-hizmet section-t8">
        <div class="container">
          <div id="news-carousel" class="swiper-container">
            <div class="swiper-wrapper">
              <div class="carousel-item-c swiper-slide">
                <div class="card-box-b news-box">
                  <div class="col-md-4">
                    <div class="card-box-c foo">
                      <div class="card-header-c d-flex">
                        <div class="card-box-ico">
                          <span class="bi bi-heart"></span>
                        </div>
                        <div class="card-title-c align-self-center">
                          <a href="#" class="link-c link-icon">
                            <h2 class="title-c">
                              Eczane <span class="bi bi-chevron-right"></span>
                            </h2>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- HIZMET SLIDER SON -->

              <div class="carousel-item-c swiper-slide">
                <div class="card-box-b news-box">
                  <div class="col-md-4">
                    <div class="card-box-c foo">
                      <div class="card-header-c d-flex">
                        <div class="card-box-ico">
                          <span class="bi bi-wifi"></span>
                        </div>
                        <div class="card-title-c align-self-center">
                          <a href="#" class="link-c link-icon">
                            <h2 class="title-c">
                              eBelediye
                              <span class="bi bi-chevron-right"></span>
                            </h2>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- HIZMET SLIDER SON -->

              <div class="carousel-item-c swiper-slide">
                <div class="card-box-b news-box">
                  <div class="col-md-4">
                    <div class="card-box-c foo">
                      <div class="card-header-c d-flex">
                        <div class="card-box-ico">
                          <span class="bi bi-geo-alt"></span>
                        </div>
                        <div class="card-title-c align-self-center">
                          <a href="#" class="link-c link-icon">
                            <h2 class="title-c">
                              360°Tur <span class="bi bi-chevron-right"></span>
                            </h2>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- HIZMET SLIDER SON -->

              <div class="carousel-item-c swiper-slide">
                <div class="card-box-b news-box">
                  <div class="col-md-4">
                    <div class="card-box-c foo">
                      <div class="card-header-c d-flex">
                        <div class="card-box-ico">
                          <span class="bi bi-cloud"></span>
                        </div>
                        <div class="card-title-c align-self-center">
                          <a href="#" class="link-c link-icon">
                            <h2 class="title-c">
                              Hava <span class="bi bi-chevron-right"></span>
                            </h2>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- HIZMET SLIDER SON -->
            </div>
          </div>

          <div class="news-carousel-pagination carousel-pagination"></div>
        </div>
      </section>
      <!-- =======  HİZMETLERİMİZ SLIDER VERSION  ======= -->

      <!-- ======= SOSYAL MEDYA TAKİP ======= -->
      <section class="section-baskan section-t8">
        <div class="container-fluid color-a gray-section">
          <div class="container">
            <div class="row text-center">
              <div class="col-md-8 d-none d-xl-block">
                <div class="socials-a">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <h2>Sosyal Medya Hesaplarımızı Takip Ettiniz mi?</h2>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-sm-12 col-xl-4">
                <div class="socials-a">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <a :href="Belediyebilgiler.face"
                        ><i class="fab fa-facebook" aria-hidden="true"></i
                      ></a>
                    </li>
                    <li class="list-inline-item">
                      <a :href="Belediyebilgiler.insta"
                        ><i class="fab fa-instagram" aria-hidden="true"></i
                      ></a>
                    </li>
                    <li class="list-inline-item">
                      <a :href="Belediyebilgiler.twit"
                        ><i class="fab fa-twitter" aria-hidden="true"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ======= SOSYAL MEDYA TAKİP ======= -->

      <!-- ======= PROJELERİMİZ ======= -->
      <section class="section-proje section-t8">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="title-wrap d-flex justify-content-between">
                <div class="title-box">
                  <h2 class="title-a">Projelerimiz</h2>
                </div>
                <div class="title-link">
                  <a href="/Projeler"
                    >Tümünü Gör
                    <span class="bi bi-chevron-right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <!-- PROJE ITEM BAŞLANGIÇ -->
            <div
              v-for="(proje, index) in Projeler.slice(0, 3)"
              :key="index"
              class="col-md-4"
            >
              <div class="card-box-d">
                <div class="card-img-d">
                  <img
                    style="object-fit: cover; height: 350px"
                    :src="proje.kapak"
                    alt=""
                    class="img-d img-fluid"
                  />
                </div>
                <div class="card-overlay card-overlay-hover">
                  <div class="card-header-d">
                    <div class="card-title-d align-self-center">
                      <h3 class="title-d">
                        <a
                          @click="projedetayagit(proje.projeId)"
                          class="link-two"
                          >{{ proje.projeAdi.slice(0, 35) }}...</a
                        >
                      </h3>
                    </div>
                  </div>
                  <div class="card-body-d">
                    <p class="content-d color-text-d">
                      {{ proje.projeAciklamasi.slice(0, 200) }}...
                    </p>
                    <div class="info-agents color-a d-none d-md-block">
                      <p>
                        <strong>Proje Tarihi: </strong> {{ proje.projeTarihi }}
                      </p>
                      <!-- <p><strong>Durumu: </strong> Devam Ediyor</p> -->
                    </div>
                  </div>
                  <div class="card-footer-d">
                    <div class="socials-footer d-flex justify-content-center">
                      <p class="intro-subtitle intro-price">
                        <a @click="projedetayagit(proje.projeId)"
                          ><span class="price-b">Devamını Oku</span></a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- PROJE ITEM SON -->
          </div>
        </div>
      </section>
      <!-- ======= PROJELERİMİZ ======= -->

      <!-- ======= BAŞKAN HAKKINDA ======= -->
      <section class="section-baskan section-t8">
        <div class="section-mayor">
          <div class="section-header">
            <img
              src="img/baskan/title-mayor.png"
              :alt="Baskanbilgisi.baskanslogan"
            />
          </div>
          <div class="container">
            <div class="mayor-photo">
              <img :src="Baskanbilgisi.kapak" :alt="Baskanbilgisi.baskanadi" />
            </div>

            <div class="col-8 mayor-info">
              <div class="text title">{{ this.Baskanbilgisi.baskanadi }}</div>
              <div class="text rank">{{ this.Baskanbilgisi.baskanslogan }}</div>
              <!-- <div class="col-8 text description d-none d-xl-block">Lorem ipsum dolor, sit amet consectetur adipisicing
              elit.
              Commodi omnis
              maiores molestias, voluptates reiciendis maxime quae velit quam harum, fugiat ab. Hic repudiandae
              temporibus possimus sunt? Dolorem eos fugit esse?
            </div> -->
            </div>
          </div>

          <div class="mayor-social center margin-top-50">
            <a :href="Baskanbilgisi.baskanface" target="_blank"
              ><svg
                class="svg-inline--fa fa-facebook-f fa-w-9"
                aria-hidden="true"
                data-prefix="fab"
                data-icon="facebook-f"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 264 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"
                ></path></svg
              ><!-- <i class="fab fa-facebook-f"></i> --></a
            >
            <a :href="Baskanbilgisi.baskantwit" target="_blank"
              ><svg
                class="svg-inline--fa fa-twitter fa-w-16"
                aria-hidden="true"
                data-prefix="fab"
                data-icon="twitter"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                ></path></svg
              ><!-- <i class="fab fa-twitter"></i> --></a
            >
            <a :href="Baskanbilgisi.baskaninsta" target="_blank"
              ><svg
                class="svg-inline--fa fa-instagram fa-w-14"
                aria-hidden="true"
                data-prefix="fab"
                data-icon="instagram"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                ></path></svg
              ><!-- <i class="fab fa-instagram"></i> --></a
            >
            <!-- <a href="" target="_blank"
              ><svg
                class="svg-inline--fa fa-youtube fa-w-18"
                aria-hidden="true"
                data-prefix="fab"
                data-icon="youtube"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                ></path></svg
              > <i class="fab fa-youtube"></i</a
            > -->
          </div>
        </div>
      </section>
      <!-- ======= BAŞKAN HAKKINDA ======= -->

      <!-- ======= GEZİLECEK YERLER ======= -->
      <section class="section-rehber section-t8">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="title-wrap d-flex justify-content-between">
                <div class="title-box">
                  <h2 class="title-a">Kent Rehberi</h2>
                </div>
                <div class="title-link">
                  <a href="/KentRehberi"
                    >Tümünü Gör
                    <span class="bi bi-chevron-right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div id="news-carousel" class="swiper-container">
            <div class="swiper-wrapper">
              <!-- BAŞLANGIÇ GEZİLECEK YERLER İTEM -->
              <div
                v-for="(yer, index) in Yerler"
                :key="index"
                class="col-md-4 carousel-item-c swiper-slide"
              >
                <div class="card-box-b card-shadow news-box">
                  <div class="img-box-b">
                    <img
                      style="object-fit: cover; height: 350px"
                      :src="yer.kapak"
                      alt=""
                      class="img-b img-fluid"
                    />
                  </div>
                  <div class="card-overlay">
                    <div class="card-header-b">
                      <div class="card-category-b">
                        <a @click="yerdetayagit(yer.yerId)" class="category-b"
                          >Detay -></a
                        >
                      </div>
                      <div class="card-title-b">
                        <h2 class="title-2">
                          <a href="#">{{ yer.yerAdi.slice(0, 30) }}...</a>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END GEZİLECEK YERLER İTEM -->
            </div>
          </div>

          <div class="news-carousel-pagination carousel-pagination"></div>
        </div>
      </section>
      <!-- ======= GEZİLECEK YERLER ======= -->

      <!-- ======= MOBİL UYGULAMA İNDİR ======= -->
      <section class="section-sosyal section-t8">
        <div class="container-fluid color-a gray-section">
          <div class="container">
            <div class="row text-center">
              <div class="col-md-8 d-none d-xl-block">
                <div class="socials-a">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <h2>Mobil Uygulamamızı İndirdiniz mi?</h2>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-sm-12 col-xl-4">
                <div class="socials-a">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <a :href="Belediyebilgiler.android"
                        ><i class="fab fa-google-play" aria-hidden="true"></i
                      ></a>
                    </li>
                    <!-- <li class="list-inline-item">
                      <a href="#"
                        ><i class="fas fa-store" aria-hidden="true"></i
                      ></a>
                    </li> -->
                    <li class="list-inline-item">
                      <a :href="Belediyebilgiler.Ios"
                        ><i class="fab fa-app-store" aria-hidden="true"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ======= MOBİL UYGULAMA İNDİR ======= -->

      <!-- ======= MECLİS KARARI ======= -->
      <!-- <section class="section-ihale section-t8 mb-5 pb-5">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="title-wrap d-flex justify-content-between">
                <div class="title-box">
                  <h2 class="title-a">Meclis Kararları</h2>
                </div>
                <div class="title-link">
                  <a href="/MeclisKararlari"
                    >Tümünü Gör
                    <span class="bi bi-chevron-right"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div id="property-carousel" class="swiper-container">
            <div class="swiper-wrapper">
              <div
                v-for="(karar, index) in Kararlar.slice(0, 9)"
                :key="index"
                class="carousel-item-b swiper-slide"
              >
                <div class="card-box-a card-shadow">
                  <div style="object-fit: cover" class="img-box-a">
                    <img
                      src="https://www.gucbirjenerator.com/wp-content/uploads/2020/04/document-download-file-files-pdf-icon-pdf-documents-png-512_512.png"
                      alt=""
                      class="img-a img-fluid"
                    />
                  </div>

                  <div class="card-overlay">
                    <div class="card-overlay-a-content">
                      <div class="card-header-a">
                        <h2 class="card-title-a">
                          <a href="#">{{ karar.kararAdi }}</a>
                        </h2>
                      </div>

                      <div class="card-body-a">
                        <div class="price-box d-flex">
                          <span class="price-a">Meclis Kararı</span>
                        </div>
                      </div>

                      <div class="card-footer-a">
                        <ul
                          class="
                            card-info
                            d-flex
                            justify-content-around
                            text-center
                          "
                        >
                          <li>
                            <h4 class="card-info-title">
                              {{ karar.kararTarihi }}
                            </h4>
                            <span
                              ><a
                                :href="karar.Dosyalar[0].dosyaurl"
                                class="link-a"
                                >Dosyayı İndir

                              </a></span
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="propery-carousel-pagination carousel-pagination"></div>
        </div>
      </section> -->
      <!-- ======= MECLİS KARARI ======= -->
      <!-- <hr /> -->
      <!-- ======= SOSYAL MEDYA AKIŞ ======= -->
      <div class="py-5 mt-5 container-fluid" id="animate-area">
        <h3
          style="
            color: black;
            text-align: center;
            padding-bottom: 30px;
            padding-top: 20px;
          "
        >
          Sosyal Medya Akışı
        </h3>
        <div class="row px-3" style="text-align: center; padding-bottom: 20px">
          <!-- <div class="col-md-4 mt-5 pt-5">
            <div
              class="fb-page fb_iframe_widget"
              data-href="https://www.facebook.com/kaymaklibelediyesi"
              data-tabs="timeline"
              data-width="1000"
              data-height="500"
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
              fb-xfbml-state="rendered"
              fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=&amp;container_width=455&amp;height=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Fkaymaklibelediyesi&amp;locale=tr_TR&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=1000"
            >
              <span style="vertical-align: bottom; width: 455px; height: 500px"
                ><iframe
                  name="f3bab171395474c"
                  width="1000px"
                  height="500px"
                  data-testid="fb:page Facebook Social Plugin"
                  title="fb:page Facebook Social Plugin"
                  frameborder="0"
                  allowtransparency="true"
                  allowfullscreen="true"
                  scrolling="no"
                  allow="encrypted-media"
                  src="https://www.facebook.com/v4.0/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df21b9ebe99e868c%26domain%3Dkaymakli.togdemyazilim.com%26origin%3Dhttp%253A%252F%252Fkaymakli.togdemyazilim.com%252Ff29234e6eaf447c%26relation%3Dparent.parent&amp;container_width=455&amp;height=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Fkaymaklibelediyesi&amp;locale=tr_TR&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=1000"
                  style="
                    border: none;
                    visibility: visible;
                    width: 455px;
                    height: 500px;
                  "
                  class=""
                ></iframe
              ></span>
            </div>
          </div> -->
          <div class="col-md-4"></div>
          <div class="col-md-4">
            <a
              class="twitter-timeline"
              data-lang="tr"
              data-width="100%"
              data-height="480"
              :href="Belediyebilgiler.twit"
              >{{ Belediyebilgiler.belediyeadi }}</a
            >
          </div>
          <div class="col-md-4"></div>

          <!-- <div class="col-md-4 mt-5 pt-5">
            <div
              class="fb-page fb_iframe_widget"
              data-href="https://www.facebook.com/TBB1945"
              data-tabs="timeline"
              data-width="1000"
              data-height="500"
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
              fb-xfbml-state="rendered"
              fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=&amp;container_width=455&amp;height=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FTBB1945&amp;locale=tr_TR&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=1000"
            >
              <span style="vertical-align: bottom; width: 455px; height: 500px"
                ><iframe
                  name="f3664f734e59cd8"
                  width="1000px"
                  height="500px"
                  data-testid="fb:page Facebook Social Plugin"
                  title="fb:page Facebook Social Plugin"
                  frameborder="0"
                  allowtransparency="true"
                  allowfullscreen="true"
                  scrolling="no"
                  allow="encrypted-media"
                  src="https://www.facebook.com/v4.0/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df27e8c64de73b98%26domain%3Ddemo4.togdemyazilim.com%26origin%3Dhttp%253A%252F%252Fdemo4.togdemyazilim.com%252Ffa94365a573168%26relation%3Dparent.parent&amp;container_width=455&amp;height=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FTBB1945&amp;locale=tr_TR&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=1000"
                  style="
                    border: none;
                    visibility: visible;
                    width: 455px;
                    height: 500px;
                  "
                  class=""
                ></iframe
              ></span>
            </div>
          </div> -->
        </div>
      </div>
      <!-- ======= SOSYAL MEDYA AKIŞ ======= -->
    </main>
    <!-- ======= MAIN ======= -->
  </div>
</template>

<script>
export default {
  name: "Home",

  data() {
    return {
      urll: "",
      Haberler: [],
      Slides: {},
      Ilanlar: [],
      Kararlar: [],
      Yerler: [],
      Baskanbilgisi: {},
      Projeler: [],
      kont: false,
      domAD: "",
      Belediyebilgiler: {},
    };
  },

  async created() {
    const domainAd = window.location.hostname;
    this.domAD = domainAd;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.belediyecek();
    await this.haberlercek();
    await this.$store.dispatch("vericekme", "Genel");
    await this.$store.dispatch("vericekme", "Slides");
    this.Slides = this.$store.getters.getSlides;
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    this.urll = `url(${this.Belediyebilgiler.kapak})`;
    await this.baskancek();
    await this.duyuruilancek();
    await this.kararlarcek();
    await this.yerlercek();
    await this.projelercek();
    this.scriptekleslide();
  },

  methods: {
    detayagit(ID) {
      this.$router.push("/HaberDetay/" + ID);
    },
    projedetayagit(ID) {
      this.$router.push("/ProjeDetay/" + ID);
    },
    yerdetayagit(ID) {
      this.$router.push("/KentDetay/" + ID);
    },
    duyurudetayagit(ID) {
      this.$router.push("/DuyuruDetay/" + ID);
    },
    async haberlercek() {
      await this.$store.dispatch("vericekme", "Haberler");
      const habermap = this.$store.getters.getHaberler;
      this.Haberler = Object.values(habermap);
      this.Haberler.sort(function (b, a) {
        return a.haberId - b.haberId;
      });
      this.kont = true;
    },
    async belediyecek() {},
    scriptekleslide() {
      let scrp1 = document.createElement("script");
      scrp1.setAttribute("src", "/js/main.js");
      document.head.appendChild(scrp1);
      let scrp2 = document.createElement("script");
      scrp2.setAttribute("src", "/js/widgets.js");
      document.head.appendChild(scrp2);
    },
    async baskancek() {
      await this.$store.dispatch("vericekme", "Baskan");
      this.Baskanbilgisi = this.$store.getters.getBaskan;
    },
    async duyuruilancek() {
      await this.$store.dispatch("vericekme", "DuyuruIlanlar");
      const dilanlar = this.$store.getters.getDuyuruIlanlar;
      this.Ilanlar = Object.values(dilanlar);
      this.Ilanlar.sort(function (b, a) {
        return a.duyruilanId - b.duyruilanId;
      });
    },
    async kararlarcek() {
      await this.$store.dispatch("vericekme", "Kararlar");
      const kararlar1 = this.$store.getters.getKararlar;
      this.Kararlar = Object.values(kararlar1);
      this.Kararlar.sort(function (b, a) {
        return a.kararId - b.kararId;
      });
      // console.log(this.Kararlar[0].Dosyalar);
    },
    async yerlercek() {
      await this.$store.dispatch("vericekme", "Yerler");
      const yerler1 = this.$store.getters.getYerler;
      this.Yerler = Object.values(yerler1);
      this.Yerler.sort(function (b, a) {
        return a.yerId - b.yerId;
      });
    },
    async projelercek() {
      await this.$store.dispatch("vericekme", "Projeler");
      const projeler1 = this.$store.getters.getProjeler;
      this.Projeler = Object.values(projeler1);
      this.Projeler.sort(function (b, a) {
        return a.projeId - b.projeId;
      });
    },
  },

  mounted() {
    if (window.location.hostname == "kaymakli.zeplinx.com") {
      let twitscrpt = document.createElement("script");
      twitscrpt.setAttribute("src", "https://platform.twitter.com/widgets.js");
      document.head.appendChild(twitscrpt);
    }
  },
};
</script>

<style>
</style>