<template>
  <div>
    <!-- ======= INTRO YAZISI ======= -->
    <section class="intro-single">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-8">
            <div class="title-single-box">
              <h1 class="title-single">Başkana Sor</h1>
              <span class="color-text-b"
                >Başkana iletmek istediğiniz mesaj için alttaki formu
                kullanabilirsiniz.</span
              >
            </div>
          </div>
          <div class="col-md-12 col-lg-4">
            <nav
              aria-label="breadcrumb"
              class="breadcrumb-box d-flex justify-content-lg-end"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/">Anasayfa</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Başkana Sor
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
    <!-- ======= INTRO YAZISI ======= -->

    <!-- ======= İLETİŞİM ======= -->
    <section class="contact">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 section-t8">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <div class="form-group">
                      <input
                        v-model="adii"
                        type="text"
                        name="name"
                        class="form-control form-control-lg form-control-a"
                        placeholder="Ad & Soyad"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <div class="form-group">
                      <input
                        v-model="gidecekmail"
                        name="email"
                        type="email"
                        class="form-control form-control-lg form-control-a"
                        placeholder="E-Posta"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-12 mb-3">
                    <div class="form-group">
                      <input
                        v-model="konu"
                        type="text"
                        name="subject"
                        class="form-control form-control-lg form-control-a"
                        placeholder="Konu"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <textarea
                        v-model="mesaj"
                        name="message"
                        class="form-control"
                        cols="45"
                        rows="8"
                        placeholder="Mesaj"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-md-12 my-3">
                    <div class="mb-3">
                      <!-- <div class="loading">Yükleniyor</div> -->
                      <div class="error-message"></div>
                      <div v-if="mailKontrol" class="sent-message">
                        Mesajınız gönderildi. Teşekkür Ederiz!
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 text-center">
                    <button
                      v-if="!mailKontrol"
                      @click="yolla()"
                      type="submit"
                      class="btn btn-b"
                    >
                      Gönder
                    </button>
                    <button
                      v-else
                      type="submit"
                      class="btn btn-b"
                      disabled="disabled"
                    >
                      Gönder
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ======= İLETİŞİM ======= -->
  </div>
</template>

<script>
import firebase from "firebase/app";
export default {
  name: "Iletisim",
  data() {
    return {
      adii: "",
      gidecekmail: "",
      mesaj: "",
      konu: "",
      Belediyebilgiler: {},
      mailKontrol: false,
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
  },
  methods: {
    async yolla() {
      const yeniIDD = Date.now().toString();
      await firebase.firestore().collection("Mailler").doc(yeniIDD).set({
        adi: this.adii,
        gidecekmail: this.gidecekmail,
        mesaj: this.mesaj,
        konu: this.konu,
        domainId: this.$store.getters.getDomainId,
      });

      this.mailKontrol = true;
    },
  },
};
</script>

<style scoped>
</style>
