
export const getBelgenel=state => state.BelediyeGenelBilgiler;
export const getBirimler=state => state.Birimler;
export const getUyeler=state => state.Uyeler;
export const getMuhtarlar=state => state.Muhtarlar;
export const getKararlar=state => state.Kararlar;
export const getRaporlar=state => state.Raporlar;
export const getProjeler=state => state.Projeler;
export const getHaberler=state => state.Haberler;
export const getBaskan=state => state.BaskanBilgileri;
export const getIlce=state  => state.IlceBilgiler
export const getSehitler=state => state.Sehitler;
export const getYerler=state => state.Yerler;
export const getVefatlar=state => state.Vefatlar;
export const getEvlenenler=state => state.Evlenenler;
export const getBelgeler=state => state.Belgeler;
export const getDuyuruIlanlar=state => state.DuyuruIlanlar;
export const getSlides = state => state.Slides;
export const getDomainId=state => state.domainId;