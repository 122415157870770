import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import BaskanOzgecmis from '../views/BaskanOzgecmis.vue'
import BaskaninMesaji from '../views/BaskaninMesaji.vue'
import BaskanGaleri from '../views/BaskanGaleri.vue'
import BaskanaSor from '../views/BaskanaSor.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Anasayfa',
    component: Home
  },

// BAŞKANIMIZ

  {
    path: '/BaskanOzgecmis',
    name: 'Başkan Özgeçmiş',
     component: BaskanOzgecmis
  },
  {
    path: '/BaskaninMesaji',
    name: 'Başkanın Mesajı',
     component: BaskaninMesaji
  },
  {
    path: '/BaskanGaleri',
    name: 'Başkan Galeri',
     component: BaskanGaleri
  },
  {
    path: '/BaskanaSor',
    name: 'Başkana Sor',
     component: BaskanaSor
  },

// KURUMSAL

  {
    path: '/Birimler',
    name: 'Birimler',
     component: () => import('../views/Birimler.vue')
  },
  {
    path: '/MeclisUyeleri',
    name: 'Meclis Üyeleri',
     component: () => import('../views/MeclisUyeleri.vue')
  },
  {
    path: '/Muhtarlar',
    name: 'Muhtarlar',
     component: () => import('../views/Muhtarlar.vue')
  },
  {
    path: '/MeclisKararlari',
    name: 'Meclis Kararları',
     component: () => import('../views/MeclisKararlari.vue')
  },
  // {
  //   path: '/FaaliyetRaporlari',
  //   name: 'Faaliyet Raporları',
  //    component: () => import('../views/FaaliyetRaporlari(iptal).vue')
  // },
  {
    path: '/Galeri',
    name: 'Galeri',
     component: () => import('../views/Galeri.vue')
  },
    
// BELEDİYE
    
  {
    path: '/Tarihce',
    name: 'Tarihçe',
     component: () => import('../views/Tarihce.vue')
  },
  {
    path: '/Haberler',
    name: 'Haberler',
     component: () => import('../views/Haberler.vue')
  },
  {
    path: '/Projeler',
    name: 'Projeler',
     component: () => import('../views/Projeler.vue')
  },
  {
    path: '/Duyurular',
    name: 'Duyurular',
     component: () => import('../views/Duyurular.vue')
  },
  {
    path: '/Ihaleler',
    name: 'İhaleler',
     component: () => import('../views/Ihaleler.vue')
  },
  {
    path: '/KentRehberi',
    name: 'KentRehberi',
     component: () => import('../views/KentRehberi.vue')
  },
  {
    path: '/Sehitlerimiz',
    name: 'Şehitlerimiz',
     component: () => import('../views/Sehitlerimiz.vue')
  },
  
// HİZMETLERİMİZ
  
  {
    path: '/VefatEdenler',
    name: 'Vefat Edenler',
     component: () => import('../views/VefatEdenler.vue')
  },
  {
    path: '/NikahDuyurulari',
    name: 'Nikah Duyuruları',
     component: () => import('../views/NikahDuyurulari.vue')
  },
  {
    path: '/BelgeOrnekleri',
    name: 'Belge Örnekleri',
     component: () => import('../views/BelgeOrnekleri.vue')
  },
  
// İLETİŞİM

  {
    path: '/Iletisim',
    name: 'Iletisim',
     component: () => import('../views/Iletisim.vue')
  },

  // ALT MENÜLER

  {
    path: '/HaberDetay/:haberId',
    name: 'Haber Detay',
     component: () => import('../views/HaberDetay.vue')
  },
    {
    path: '/DuyuruDetay/:duyuruId',
    name: 'Duyuru Detay',
     component: () => import('../views/DuyuruDetay.vue')
  },
  {
    path: '/ProjeDetay/:projeId',
    name: 'Proje Detay',
     component: () => import('../views/ProjeDetay.vue')
  },
  {
    path: '/KentDetay/:kentId',
    name: 'Kent Detay',
     component: () => import('../views/KentDetay.vue')
  },
  // {
  //   path: '/IhaleDetay',
  //   name: 'İhale Detay',
  //    component: () => import('../views/IhaleDetay(sanırımiptal).vue')
  // },
  {
    path: '/BirimDetay/:birimId',
    name: 'Birim Detay',
     component: () => import('../views/BirimDetay.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
