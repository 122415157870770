<template>
  <div>
    <!-- ======= ARAMA MENUSU ======= -->
    <SearchBar />
    <!-- ======= ARAMA MENUSU ======= -->

    <!-- ======= NAVBAR ======= -->
    <Header />
    <!-- ======= NAVBAR ======= -->

    <!-- MAIN -->
    <router-view />
    <!-- MAIN -->

    <!-- ======= FOOTER ======= -->
    <Footer />
    <!-- ======= FOOTER ======= -->

    <!-- YÜKLENİYOR EKRANI ALTTAKİ SCRİPTLERİ ETKİNLEŞTİRİNCE HATA VERİYOR SONSUZA KADAR DÖNÜYOR //ÇÖZ -->
    <div v-if="!loadkont" id="preloader"></div>

    <backToTop />
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import BackToTop from "@/components/BackToTop.vue";
import SearchBar from "@/components/SearchBar.vue";
export default {
  name: "App",

  data() {
    return {
      Belediyebilgiler: {},
      loadkont: false,
    };
  },

  async created() {
    const test1 = document.documentElement;

    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    const belediye = this.$store.getters.getBelgenel;
    this.loadkont = true;

    test1.style.setProperty("--anacolor", belediye.anarenk);
    test1.style.setProperty("--headcolor", belediye.headrenk);
    document.title = belediye.belediyeadi + " Belediyesi Resmi Web Sitesi";
    // document.getElementById("ic1").href = belediye.kapak;
    // document.getElementById("ic2").href = belediye.kapak;
    let scrp1 = document.createElement("script");
    scrp1.setAttribute("src", "/js/main.js");
    document.head.appendChild(scrp1);
  },

  components: {
    Header,
    Footer,
    BackToTop,
    SearchBar,
  },
};
</script>
